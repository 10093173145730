var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('b-list-group', [_vm.isNotificationLoading ? _c('div', [_c('div', {
    staticClass: "text-center"
  }, [_c('b-spinner', {
    staticClass: "mr-1",
    staticStyle: {
      "width": "3rem",
      "height": "3rem"
    },
    attrs: {
      "variant": "primary"
    }
  })], 1)]) : _c('div', _vm._l(_vm.notificationList, function (item, index) {
    var _item$data3, _item$data4;
    return _c('b-list-group-item', {
      key: index,
      style: _vm.setItemStyle(item)
    }, [_c('b-link', {
      on: {
        "click": function click($event) {
          return _vm.showNotificationDetails(item);
        }
      }
    }, [_c('b-media', {
      scopedSlots: _vm._u([{
        key: "aside",
        fn: function fn() {
          var _item$data, _item$data2;
          return [_c('b-avatar', {
            attrs: {
              "size": "32",
              "variant": item === null || item === void 0 ? void 0 : (_item$data = item.data) === null || _item$data === void 0 ? void 0 : _item$data.variant
            }
          }, [_c('feather-icon', {
            attrs: {
              "icon": item === null || item === void 0 ? void 0 : (_item$data2 = item.data) === null || _item$data2 === void 0 ? void 0 : _item$data2.icon
            }
          })], 1)];
        },
        proxy: true
      }], null, true)
    }, [_c('p', {
      staticClass: "media-heading"
    }, [_c('span', {
      staticClass: "font-weight-bolder"
    }, [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$data3 = item.data) === null || _item$data3 === void 0 ? void 0 : _item$data3.message) + " ")])]), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(_vm._s(item === null || item === void 0 ? void 0 : (_item$data4 = item.data) === null || _item$data4 === void 0 ? void 0 : _item$data4.sub_title))]), _c('br'), _c('small', {
      staticClass: "notification-text"
    }, [_vm._v(" " + _vm._s(_vm._f("moment")(item === null || item === void 0 ? void 0 : item.created_at, "ddd, MMM Do YYYY, h:mm a")) + " ")])])], 1)], 1);
  }), 1)]), _c('div', {
    staticClass: "mt-2"
  }, [_c('div', {
    staticClass: "demo-spacing-0"
  }, [_c('b-pagination', {
    attrs: {
      "total-rows": _vm.rows,
      "per-page": _vm.perPage,
      "first-number": "",
      "last-number": "",
      "prev-class": "prev-item",
      "next-class": "next-item"
    },
    on: {
      "input": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "prev-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronLeftIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }, {
      key: "next-text",
      fn: function fn() {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ChevronRightIcon",
            "size": "18"
          }
        })];
      },
      proxy: true
    }]),
    model: {
      value: _vm.currentPage,
      callback: function callback($$v) {
        _vm.currentPage = $$v;
      },
      expression: "currentPage"
    }
  })], 1)])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }