<template>
  <div>
    <b-list-group>
      <div v-if="isNotificationLoading">
        <div class="text-center">
          <b-spinner
            style="width: 3rem; height: 3rem"
            class="mr-1"
            variant="primary"
          />
        </div>
      </div>
      <div v-else>
        <b-list-group-item
          v-for="(item, index) in notificationList"
          :key="index"
          :style="setItemStyle(item)"
        >
          <b-link v-on:click="showNotificationDetails(item)">
            <b-media>
              <template #aside>
                <b-avatar size="32" :variant="item?.data?.variant">
                  <feather-icon :icon="item?.data?.icon" />
                </b-avatar>
              </template>
              <p class="media-heading">
                <span class="font-weight-bolder">
                  {{ item?.data?.message }}
                </span>
              </p>
              <small class="notification-text">{{
                item?.data?.sub_title
              }}</small
              ><br />
              <small class="notification-text">
                {{ item?.created_at | moment("ddd, MMM Do YYYY, h:mm a") }}
              </small>
            </b-media>
          </b-link>
        </b-list-group-item>
      </div>
    </b-list-group>

    <div class="mt-2">
      <div class="demo-spacing-0">
        <b-pagination
          v-model="currentPage"
          :total-rows="rows"
          :per-page="perPage"
          first-number
          last-number
          prev-class="prev-item"
          next-class="next-item"
          @input="onPageChange"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>
    </div>
  </div>
</template>

  <script>
import {
  BListGroup,
  BListGroupItem,
  BPagination,
  BSpinner,
  BMedia,
  BAvatar,
  BLink,
} from "bootstrap-vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
export default {
  components: {
    BListGroup,
    BListGroupItem,
    BPagination,
    BSpinner,
    BMedia,
    BAvatar,
    BLink,
  },
  data() {
    return {
      notificationList: [],
      currentPage: 1,

      rows: "",
      perPage: "",
      isNotificationLoading: false,
    };
  },

  async created() {
    this.loadItems();
  },

  methods: {
    onPageChange() {
      this.loadItems();
    },
    async loadItems() {
      try {
        this.isNotificationLoading = true;
        const allNotifications = await this.$api.get(
          "api/notifications/user/all",
          {
            params: {
              currentPage: this.currentPage,
            },
          }
        );

        this.notificationList = allNotifications?.data?.data?.data;

        this.rows = allNotifications?.data?.data?.total;
        this.perPage = allNotifications?.data?.data?.per_page;
        this.isNotificationLoading = false;
      } catch (error) {
        this.isNotificationLoading = false;
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    async markNotificationAsRead(notification) {
      try {
        await this.$api.put(
          `api/notification/${notification?.id}/mark-as-read`
        );
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    showNotificationDetails(notification) {
      if (notification?.data?.url) {
        const currentPageURL = window.location.href;
        const baseUrl = new URL(currentPageURL).origin;
        const notificationUrl = notification?.data?.url;
        const routeUrl = `${baseUrl}/${notificationUrl}`;
        window.open(routeUrl);
      }

      this.markNotificationAsRead(notification);
    },
    setItemStyle(item) {
      // Define your style logic based on item properties
      if (item.read_at) {
        return {
          backgroundColor: "#f1f1f1",
        };
      } else {
        return {}; // Default style or empty object
      }
    },
  },
};
</script>
